.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ql-editor  {
  content: '25';
  font-size: 25px !important;
  text-align: right !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}


.cursor-pointer {
  cursor: pointer;
}

.badge-4142{
  background-color: indigo;
  color: #ffffff;
  font-size: 14pt;
}
.badge-4024 {
  color: #ffffff;
  background-color: black;
  font-size: 14pt;
}

.badge-4060 {
  color: black;
  background-color: yellow;
  font-size: 14pt;
}
.badge-2300 {
  color: black;
   
  font-size: 14pt;
}
.badge-4148 {
  color: black;
  background-color: coral;
  font-size: 14pt;
}

.badge-3877 {
  color: black;

  font-size: 14pt;
}

.badge-3772 {
  color: black;

  font-size: 14pt;
}

.badge-3839 {
  color: black;

  font-size: 14pt;
}

.badge-2665 {
  color: black;

  font-size: 14pt;
}
 
.badge-3900 {
  color: #ffffff;
  background-color: deeppink;
  font-size: 14pt;
}

.badge-6877 {
  color: #ffffff;
  background-color: brown;
  font-size: 14pt;
}

.badge-2304 {
  color: #ffffff;
  background-color: rgb(255, 0, 0);
  font-size: 14pt;
}

.badge-4022 {
  color: #ffffff;
  background-color: #8e44ad;
  font-size: 14pt;
}

.badge-2337 {
  color: #ffffff;
  background-color: blue;
  font-size: 14pt;
}

.badge-6055 {
  color: #ffffff;
  background-color: darkgreen;
  font-size: 14pt;

  
}

.selectStatusList{
    font-size: 14pt;
  line-height: 1.8;
    font-weight: bold;
}

 

.row4148color {
  color:coral;
  font-weight: bold;
  font-size: large;
}



.row4022color {
  color: #8e44ad;
  font-weight: bold;
    font-size: large;
}

.row2337color {
  color: blue;
    font-weight: bold;
      font-size: large;
}

.row6877color {
  color: brown;
  font-weight: bold;
    font-size: large;
}

.row4142color {
  color: indigo;
  font-weight: bold;
  font-size: large;
}
 
.row2304color {
  color: rgb(255, 0, 0);
  font-weight: bold;
    font-size: large;
}

.row4024color {
  color: black;
  font-weight: bold;
    font-size: large;
}

.row3900color {
  color: deeppink;
  font-weight: bold;
    font-size: large;
}

.row6055color {
  color: #0b7c3e;
  font-weight: bold;
    font-size: large;
}

.row4060background-color {
  background-color: yellow;
  font-weight: bold;
  font-size: large;
  
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}




.row4022colorEditor {
  color: #8e44ad;
  
}

.row2337colorEditor {
  color: blue;
}

.row6877colorEditor {
  color: brown;
 
}

.row4142colorEditor {
  color: indigo;
 
}

.row2304colorEditor {
  color: rgb(255, 0, 0);
 
}

.row4024colorEditor {
  color: black;
 
}

.row3900colorEditor {
  color: deeppink;
 
}

.row6055colorEditor {
  color: #0b7c3e;
 
}
textarea::placeholder {
  font-weight: normal;
  opacity: .6;
  color: gray;
}

.textareastyle{
    font-weight: bold;
    text-align: right;
      font-size: large;
      box-sizing: border-box;
        padding: 1rem;
        border-radius: 1rem;
        background-color: hsl(0, 0%, 100%);
        border: 1px solid hsl(228, 98%, 48%);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 95%;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
